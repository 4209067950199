import { Accuracy, Importance } from '../api/websiteKnowledgeBase'

const accuracyOptions = [
  {
    value: Accuracy.ALL,
    label: 'All'
  },
  {
    value: Accuracy.CORRECT,
    label: 'Correct',
  },
  {
    value: Accuracy.PARTIALLY_CORRECT,
    label: 'Partially Correct',
  },
  {
    value: Accuracy.OUTSIDE_OF_KNOWLEDGE,
    label: 'Outside of knowledge',
  },
  {
    value: Accuracy.INCORRECT,
    label: 'Incorrect',
  },
]

const accuracyFormOptions = [
  {
    value: Accuracy.CORRECT,
    label: 'Correct',
  },
  {
    value: Accuracy.PARTIALLY_CORRECT,
    label: 'Partially Correct',
  },
  {
    value: Accuracy.OUTSIDE_OF_KNOWLEDGE,
    label: 'Outside of knowledge',
  },
  {
    value: Accuracy.INCORRECT,
    label: 'Incorrect',
  },
]

const importanceOptions = [
  {
    value: Importance.HIGH,
    label: 'High',
  },
  {
    value: Importance.MEDIUM,
    label: 'Medium',
  },
  {
    value: Importance.LOW,
    label: 'Low',
  },
]

export { accuracyOptions, importanceOptions, accuracyFormOptions }
