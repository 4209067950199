import styled from 'styled-components'

export const Form = styled.div<{ height: number }>`
  width: 650px;
  height: ${({ height }) => height};
  position: absolute;
  background: var(--color-white);
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.086);
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  flex-direction: column;
  padding: 24px;  
`

export const SelectorWrap = styled.div`
    width: 100%;
    margin-bottom: 8px;
`

export const Button = styled.button`
  background: var(--color-button-primary);
  min-width: 80px;
  height: 48px;
  border-radius: 10px;
  border: none;
  color: var(--color-text-on-primary);
  font-size: 16px;
  &:disabled {
    color: var(--color-text-primary);
    background: var(--color-disabled-fill);
  }
`

export const Textarea = styled.textarea<{ isError: boolean; isFromEdit: boolean }>`
    position: relative;
    height: ${({ isFromEdit }) => (isFromEdit ? '250px' : '176px')};
    font-size: 14px;
    padding: 16px 24px 10px 16px;
    word-break: break-word;
    border-color: ${({ isError }) => isError && 'var(--color-text-error)'};
    z-index: 0;
`

export const Error = styled.div`
    font-size: 13px;
    color: var(--color-text-error);
    margin-top: -4px;
    margin-bottom: 8px;
    min-height: 16px;
`

export const LoaderWrap = styled.div`
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
`

