import styled from 'styled-components'

const ExternalLinkWrap = styled.a`
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: 2px;  
`

const EditIconWrap = styled.button`
  all: unset;
  position: relative;

  & svg path {
    fill: var(--color-brandeis-blue);
  }
`

const SourceWrap = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${props => props.backgroundColor};
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
`

const LinkIconWrap = styled.button`
  all: unset;
`

const MessageWrap = styled.span`
  word-break: break-word;
  white-space: pre-wrap;
`

export { ExternalLinkWrap, SourceWrap, LinkIconWrap, MessageWrap, EditIconWrap }
