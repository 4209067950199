import React from 'react'
import {withStyles} from '@material-ui/core'

import {customStyles, styles} from '../LanguageSelector/styles'
import {withRouter} from 'react-router-dom'
import Select from 'react-select'

const Selector = props => {
  const {onChange, value, styles, options} = props

  return (
    <Select
      options={options}
      value={value}
      styles={styles || customStyles}
      isSearchable={false}
      onChange={onChange}
      components={styles}
    />
  )
}

export default withRouter(withStyles(styles)(Selector))
