import api from 'services/api'
import { logoutOnRequestOrResponseJson, customResponseParser, alertError } from 'api'
import { BASE_URL } from 'configs'
import { SourceTypes } from '../constants'
import { addQueryStringParameter } from '../../../helpers'

export const getKnowledgeSources = (botId: number, type: string) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge?type=${type}`)
}

export const getTrainingStatus = (botId: number) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/status`)
}

export const updateWebsiteKnowledgeSources = (
  botId: number,
  body: { url: string; type: SourceTypes },
): Promise<{ id: string; type: SourceTypes; url: string } | undefined | null> => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload`, body)
}

export const updateTextKnowledgeSources = (
  botId: number,
  body: { title: string; text: string; replyId?: number; type: SourceTypes },
  needTrain: boolean,
) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/upload?train=${needTrain}`, body)
}

export const updateFileKnowledgeSources = (botId: number, body, signal) => {
  return fetch(`${BASE_URL}/bot/${botId}/knowledge/upload`, {
    credentials: 'include',
    method: 'POST',
    body: body,
    signal: signal,
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response))
}

export const getTextInsertSource = (
  botId: number,
  improvementSourceId: string,
): Promise<{ type: SourceTypes; status: string; _id: string; title?: string; text?: string }> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/${improvementSourceId}`)
}

export const updateTextInsert = (
  botId: number,
  payloadBody: { title: string; text?: string; type: SourceTypes; _id: string },
): Promise<{ replyId: number; status: string; title: string; text?: string; type: SourceTypes }> => {
  return api.put(`${BASE_URL}/bot/${botId}/knowledge/update`, payloadBody)
}

export const saveNLPConfig = (
  botId: number,
  payload: { prompt: string; model: string },
): Promise<{ data: string } | undefined> => {
  return api.put(`${BASE_URL}/bot/${botId}/knowledge/config`, payload)
}

export const getDefaultCustomPrompt = (): Promise<string> => {
  return api.get(`${BASE_URL}/knowledge/prompt/default`)
}

export const getNLPConfig = (botId: number): Promise<{ prompt: string; model: string } | undefined> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/config`)
}

export const getAvailableGPTModels = (): Promise<string[] | undefined> => {
  return api.get(`${BASE_URL}/knowledge/models`)
}

export const deleteKnowledgeSources = (botId: number, id: number) => {
  return api.delete(`${BASE_URL}/bot/${botId}/knowledge?source_id=${id}`)
}

export const deleteSourceSubPage = (botId: number, sourceId: string, pageId: string) => {
  return api.delete(`${BASE_URL}/bot/${botId}/knowledge/${sourceId}/page/${pageId}`)
}

export const trainKnowledgeSources = (botId: number, body: string[]) => {
  return api.post(`${BASE_URL}/bot/${botId}/knowledge/train`, body)
}

export const getKnowledgeSourcePages = (
  sourceId: string,
  botId: number,
): Promise<{ url: string; _id?: string }[] | undefined> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/${sourceId}/pages`)
}

type getSuccessfulKnowledgeBaseReplyCountParams = {
  botId: number,
  startDate: string,
  endDate: string
}
export enum Reaction {
  // eslint-disable-next-line no-unused-vars
  ALL='ALL',
  // eslint-disable-next-line no-unused-vars
  EMPTY='EMPTY',
  // eslint-disable-next-line no-unused-vars
  GOOD='GOOD',
  // eslint-disable-next-line no-unused-vars
  BAD='BAD',
}
export enum Accuracy {
  // eslint-disable-next-line no-unused-vars
  ALL = 'ALL',
  // eslint-disable-next-line no-unused-vars
  CORRECT = 'CORRECT',
  // eslint-disable-next-line no-unused-vars
  PARTIALLY_CORRECT = 'PARTIALLY_CORRECT',
  // eslint-disable-next-line no-unused-vars
  INCORRECT = 'INCORRECT',
  // eslint-disable-next-line no-unused-vars
  OUTSIDE_OF_KNOWLEDGE = 'OUTSIDE_OF_KNOWLEDGE',
}
export enum Importance {
  // eslint-disable-next-line no-unused-vars
  HIGH = 'HIGH',
  // eslint-disable-next-line no-unused-vars
  MEDIUM = 'MEDIUM',
  // eslint-disable-next-line no-unused-vars
  LOW = 'LOW',
}
type getKnowledgeBaseRepliesParams = {
  botId: number
  page: number
  size: number
  startDate: string
  endDate: string
  languageId?: string | number
  reaction: Reaction
  accuracy: Accuracy
  search?: string
}
export type AiFeedback = {
  id: number
  isGood: boolean
  message?: string
}
export type KnowledgeReplyComment = {
  topic: string
  comment: string
  importance: Importance
  accuracy: Accuracy
}
export type KnowledgeBaseReply = {
  id: number
  improvementSourceId?: string
  userId: number
  userPhrase: string
  odinReply: string
  source?: string
  sourceType?: SourceTypes
  sourceId?: string
  sourceName?: string
  sentAt: string
  isFailed: boolean
  isStoppedByUser?: boolean
  aiFeedback?: AiFeedback
  knowledgeReplyComment: KnowledgeReplyComment
}
export const getKnowledgeBaseReplies = ({
  botId,
  page,
  size,
  startDate,
  endDate,
  languageId,
  reaction,
  accuracy,
  search,
}: getKnowledgeBaseRepliesParams): Promise<{ data: KnowledgeBaseReply[]; totalElements: number }> => {
  // eslint-disable-next-line max-len
  let url = `${BASE_URL}/bot/${botId}/knowledge/replies?page=${page}&size=${size}&from=${startDate}&to=${endDate}&reaction=${reaction}&accuracy=${accuracy}`

  if (languageId && languageId !== 'all') url = addQueryStringParameter(url, 'languageId', String(languageId))

  if (search) url = addQueryStringParameter(url, 'searchQuery', search)

  return api
    .get(url)
    .then(res => {
      return { data: res.content, totalElements: res.totalElements }
    })
    .catch(() => {
      alertError('Sorry, something went wrong. Please, ping support!')
      return { data: [], totalElements: 0 }
    })
}

export const updateKnowledgeReplyComment = (
  { botId, replyId, comment }: { botId: number; replyId: number; comment: KnowledgeReplyComment },
): Promise<void> => {
  const url = `${BASE_URL}/bot/${botId}/knowledge/admin-comment/${replyId}`
  return api
    .post(url, comment).then(
      () => {
        return
      },
    )
    .catch(() => {
      alertError('Sorry, something went wrong. Please, ping support!')
    })
}

export const getSuccessfulKnowledgeBaseReplyCount = ({
  botId,
  startDate,
  endDate} : getSuccessfulKnowledgeBaseReplyCountParams): Promise<{ count: number }> => {
  const url = `${BASE_URL}/bot/${botId}/knowledge/replies/countSuccessful?from=${startDate}&to=${endDate}`
  return api
    .get(url)
    .catch(() => {
      alertError('Sorry, something went wrong. Please, ping support!')
      return { count: 0 }
    })
}

export const getAllKnowledgeReplyCommentTopics = ({
  botId,
}: {
  botId: number
}): Promise<{ topics: string[] }> => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge/admin-comment/topics`).then(
    res => {
      return {
        topics: res,
      }
    },
  ).catch(() => {
    return {
      topics: [
        'Document retrieval',
        'Company information',
        'Product specs information',
        'Inventory information',
        'Price information',
        'Product recommendation',
        'HR information',
      ],
    }
  })
}
