import React, {ChangeEvent, FC, useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Reducers from 'reducers'
import {DateControlContext} from 'contexts/DateControlContext'
import {useDebounce} from 'hooks/useDebounce'
import Search from 'uiKit/Search/Search'
import Selector from '../../../../uiKit/KnowledgeReplyCommentForm/Selector'
import ReactionsSelector, {reactionOptions} from 'uiKit/ReactionsSelector/ReactionsSelector'
import Pagination from 'uiKit/table/Pagination'
import NoChartData from 'uiKit/NoChartData'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import SelectorStyles from 'tabs/nlp/constants/selectorStyles'
import {ROWS_PER_PAGE} from 'tabs/nlp/constants/paginationSize'
import {
  getKnowledgeBaseReplies,
  getSuccessfulKnowledgeBaseReplyCount, KnowledgeReplyComment,
  Reaction, updateKnowledgeReplyComment,
} from 'tabs/nlp/api/websiteKnowledgeBase'

import * as S from './KnowledgeBaseReplies.style'
import {KnowledgeBaseRepliesTable} from './KnowledgeBaseRepliesTable'
import { accuracyOptions } from '../../constants/selectorOptions'

const inputSearchStyles = {borderRadius: 10, height: '45px', padding: '14px 24px', minWidth: 200}
const debounceDelay = 500

export const KnowledgeBaseReplies: FC = () => {
  const {dateRange} = useContext(DateControlContext)
  const botId: number = useSelector((state: ReturnType<typeof Reducers>) => state.activeBot.id)
  const [loadingReplies, setLoadingReplies] = useState(true)
  const [knowledgeBaseReplies, setKnowledgeBaseReplies] = useState({data: [], totalElements: 0})

  const [loadingSuccessfulReplyCount, setLoadingSuccessfulReplyCount] = useState(true)
  const [successfulReplyCount, setSuccessfulReplyCount] = useState(0)

  const [loadingCommentSave, setLoadingCommentSave] = useState(false)

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearch = useDebounce(searchValue, debounceDelay)

  const [selectedReaction, setSelectedReaction] =
    useState<{ value: Reaction; label: string; id?: number }>(reactionOptions[0])
  const [selectedAccuracyOption, setSelectedAccuracyOption] = useState(accuracyOptions[0])

  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
    fetchKnowledgeReplies(0)
  }, [debouncedSearch, dateRange, selectedReaction, selectedAccuracyOption])

  const fetchKnowledgeReplies = (page: number) => {
    getKnowledgeBaseReplies({
      botId,
      page,
      size: ROWS_PER_PAGE,
      startDate: dateRange.startDate.format('YYYY-MM-DD'),
      endDate: dateRange.endDate.format('YYYY-MM-DD'),
      reaction: selectedReaction.value,
      accuracy: selectedAccuracyOption.value,
      search: debouncedSearch.toLowerCase(),
    }).then(res => {
      setLoadingReplies(false)
      setKnowledgeBaseReplies(res)
    })
  }

  const handleUpdateKnowledgeReplyComment = (botId: number, replyId: number, comment: KnowledgeReplyComment) => {
    setLoadingCommentSave(true)
    const requestParams = {botId, replyId, comment}
    updateKnowledgeReplyComment(requestParams)
      .then(() => {
        fetchKnowledgeReplies(page)
        setLoadingCommentSave(false)
      })
  }

  useEffect(() => {
    fetchSuccessfulKnowledgeReplyCount()
  }, [dateRange])

  const fetchSuccessfulKnowledgeReplyCount = () => {
    const requestParams = {
      botId: botId,
      startDate: dateRange.startDate.format('YYYY-MM-DD'),
      endDate: dateRange.endDate.format('YYYY-MM-DD'),
    }
    getSuccessfulKnowledgeBaseReplyCount(requestParams)
      .then(res => {
        setLoadingSuccessfulReplyCount(false)
        setSuccessfulReplyCount(res.count)
      })
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleChangePage = (_, page) => {
    setPage(page)
    fetchKnowledgeReplies(page)
  }

  return (
    <S.KnowledgeBaseContainer>
      <S.TableTitleWithFilters>
        <S.Header>
          <S.TitleWrap>
            <S.Title>AI Knowledge replies</S.Title>
          </S.TitleWrap>
        </S.Header>
        <S.FiltersWrap>
          <S.SearchWrap>
            <Search
              value={searchValue}
              onChange={handleSearchChange}
              placeholder="Search"
              inputStyle={inputSearchStyles}
            />
          </S.SearchWrap>
          <S.SelectorWrap>
            <Selector
              onChange={setSelectedAccuracyOption}
              value={selectedAccuracyOption}
              styles={SelectorStyles}
              options={accuracyOptions}
            />
          </S.SelectorWrap>
          <S.SelectorWrap>
            <ReactionsSelector
              onChange={setSelectedReaction}
              value={selectedReaction}
              styles={SelectorStyles}
            />
          </S.SelectorWrap>
        </S.FiltersWrap>
      </S.TableTitleWithFilters>
      {(loadingReplies || loadingSuccessfulReplyCount || loadingCommentSave) && <LoaderScreen/>}

      {!loadingSuccessfulReplyCount &&
        <S.SuccessfulReplyCountWrap>Successful AI replies: {successfulReplyCount}</S.SuccessfulReplyCountWrap>}

      {!loadingReplies &&
        (knowledgeBaseReplies.data.length ? (
          <>
            <KnowledgeBaseRepliesTable
              knowledgeBaseReplies={knowledgeBaseReplies.data}
              botId={botId}
              page={page}
              fetchKnowledgeReplies={fetchKnowledgeReplies}
              updateKnowledgeReplyComment={handleUpdateKnowledgeReplyComment}
            />
            <Pagination
              page={page}
              rowsPerPage={ROWS_PER_PAGE}
              count={knowledgeBaseReplies.totalElements}
              onChangePage={handleChangePage}
            />
          </>
        ) : (
          <NoChartData text="No data with the selected parameters"/>
        ))}
    </S.KnowledgeBaseContainer>
  )
}
