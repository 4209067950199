/* eslint-disable max-len */
import React from 'react'

interface CommentAddIconProps {
  width?: number;
  height?: number;
  color?: string;
}

export const AddCommentIcon: React.FC<CommentAddIconProps> = ({
  width = 22,
  height = 22,
  color = 'var(--color-button-primary)',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="commentAddTitle commentAddDesc"
      role="img"
    >
      <title id="commentAddTitle">Add Comment Icon</title>
      <desc id="commentAddDesc">Icon representing adding a comment</desc>
      <g stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M3 5V20.7929C3 21.2383 3.53857 21.4614 3.85355 21.1464L7.70711 17.2929C7.89464 17.1054 8.149 17 8.41421 17H19C20.1046 17 21 16.1046 21 15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5Z" />
      </g>
    </svg>
  )
}
