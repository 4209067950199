import React from 'react'
import {withStyles} from '@material-ui/core'

import {customStyles, styles} from '../LanguageSelector/styles'
import {withRouter} from 'react-router-dom'
import Creatable from 'react-select/lib/Creatable'

const CreatableSelector = props => {
  const {onChange, value, styles, options = false, handleCreate} = props

  return (
    <Creatable
      value={value}
      onChange={onChange}
      onCreateOption={handleCreate}
      options={options}
      placeholder="Select or create an option..."
      styles={styles || customStyles}
      isSearchable={true}
      components={styles}
    />
  )
}

export default withRouter(withStyles(styles)(CreatableSelector))
