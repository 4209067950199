import React, { useEffect, useState, useCallback } from 'react'
import ModalTemplate from 'uiKit/ModalTemplate'
import classes from './styles.module.scss'
import * as S from './KnowledgeReplyCommentForm.style'
import Label from '../../../../uiKit/texts/Label'
import SelectorStyles from '../../constants/selectorStyles'
import Selector from '../../../../uiKit/KnowledgeReplyCommentForm/Selector'
import CreatableSelector from '../../../../uiKit/KnowledgeReplyCommentForm/CreatableSelector'
import { getAllKnowledgeReplyCommentTopics, KnowledgeReplyComment } from '../../api/websiteKnowledgeBase'
import { maxTextLength } from '../../constants'
import { accuracyFormOptions, importanceOptions } from '../../constants/selectorOptions'

interface EditKnowledgeReplyCommentProps {
  isOpen: boolean;
  onClose: () => void;
  currentSelectedOptions: KnowledgeReplyComment | null;
  botId: number;
  replyId: number;
  page: number;
  fetchKnowledgeReplies: (page: number) => void;
  updateKnowledgeReplyComment: (botId: number, replyId: number, comment: KnowledgeReplyComment) => void;
}

export const KnowledgeReplyCommentForm: React.FC<EditKnowledgeReplyCommentProps> = ({
  isOpen,
  onClose,
  currentSelectedOptions,
  botId,
  replyId,
  page,
  fetchKnowledgeReplies,
  updateKnowledgeReplyComment,
}) => {
  const [initialAccuracy] = useState(accuracyFormOptions.find(
    (option) => option.value === currentSelectedOptions?.accuracy)
    ?? accuracyFormOptions[0])
  const [initialTopic, setInitialTopic] = useState({
    value: currentSelectedOptions?.topic ?? '',
    label: currentSelectedOptions?.topic ?? '',
  })
  const [initialImportance] = useState(importanceOptions.find((option) =>
    option.value === currentSelectedOptions?.importance) ?? importanceOptions[0])
  const [initialAdminComment] = useState(currentSelectedOptions?.comment ?? '')

  const [accuracyOption, setSelectedAccuracyOption] = useState(initialAccuracy)
  const [topic, setTopic] = useState(initialTopic)
  const [importance, setImportance] = useState(initialImportance)
  const [adminComment, setAdminComment] = useState(initialAdminComment)
  const [topicsOptions, setTopics] = useState<{ value: string; label: string }[]>([])

  const [errorText, setErrorText] = useState('')

  const [isDirty, setIsDirty] = useState<boolean>(false)

  useEffect(() => {
    if (
      accuracyOption !== initialAccuracy ||
      topic !== initialTopic ||
      importance !== initialImportance ||
      adminComment !== initialAdminComment
    ) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [accuracyOption, topic, importance, adminComment, initialAccuracy,
    initialTopic, initialImportance, initialAdminComment])

  useEffect(() => {
    getAllKnowledgeReplyCommentTopics({ botId }).then((res) => {
      const topics = res.topics.map((t) => ({
        value: t,
        label: t,
      }))
      setTopics(topics)
    })
    if (initialTopic.value === '') {
      setTopic(topicsOptions[0])
      setInitialTopic(topicsOptions[0])
    }
  }, [botId])

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  const handleCloseModal = useCallback(() => {
    if (isDirty) {
      const confirmClose = window.confirm(
        'You have unsaved changes. Are you sure you want to leave the page?',
      )
      if (!confirmClose) {
        return
      }
    }
    onClose()
  }, [isDirty, onClose])


  const handleSubmit = () => {
    updateKnowledgeReplyComment(botId, replyId, {
      accuracy: accuracyOption.value,
      topic: topic.value,
      importance: importance.value,
      comment: adminComment,
    })

    fetchKnowledgeReplies(page)
    onClose()
  }

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value
    if (inputValue.length > maxTextLength) {
      setErrorText(`You've reached the max length for the text value — ${maxTextLength} characters`)
    } else {
      setErrorText('')
    }
    setAdminComment(inputValue)
  }

  const handleNewTopicCreate = (newTopic: string) => {
    setTopics((prevTopics) => {
      return [...prevTopics, { value: newTopic, label: newTopic }]
    })
    setTopic({
      value: newTopic,
      label: newTopic,
    })
  }

  return (
    <ModalTemplate
      open={isOpen}
      title="Add Admin Feedback"
      onClose={handleCloseModal}
      containerClasses={classes.modalKnowledgeReplyCommentContainer}
      withFooter={false}
    >
      <S.Form>
        <Label children="AI Accuracy" />
        <S.SelectorWrap>
          <Selector
            onChange={setSelectedAccuracyOption}
            value={accuracyOption}
            options={accuracyFormOptions}
            styles={SelectorStyles}
          />
        </S.SelectorWrap>

        <Label children="Topic" />
        <S.SelectorWrap>
          <CreatableSelector
            onChange={setTopic}
            value={topic}
            options={topicsOptions}
            styles={SelectorStyles}
            onCreateOption={handleNewTopicCreate}
          />
        </S.SelectorWrap>

        <Label children="Importance" />
        <S.SelectorWrap>
          <Selector
            onChange={setImportance}
            value={importance}
            options={importanceOptions}
            styles={SelectorStyles}
          />
        </S.SelectorWrap>

        <Label children="Admin Comment" />
        <S.Textarea
          placeholder="+ Insert text here"
          value={adminComment}
          onChange={handleTextChange}
          isError={Boolean(errorText)}
        />
        <S.Error>{errorText}</S.Error>
        <S.Button onClick={handleSubmit} disabled={!isDirty}>
          Save
        </S.Button>
      </S.Form>
    </ModalTemplate>
  )
}
