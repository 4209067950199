import React, { FC } from 'react'

import * as S from './AddComment.style'
import { AddCommentIcon } from '../../../../uiKit/icons/AddCommentIcon'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'

interface AddCommentProps {
  onClick?: (event, index: number | string) => void
  tooltipTitle?: string
  tooltipPlacement?: TooltipProps['placement']
  tooltipClasses?: Record<string, string>
}

export const AddComment: FC<AddCommentProps> = ({ onClick, tooltipPlacement, tooltipClasses, tooltipTitle }) => {
  return (
    <Tooltip placement={tooltipPlacement || 'top'} title={tooltipTitle || 'Add comment'} classes={tooltipClasses}>
      <S.AddCommentWrap onClick={onClick}>
        <AddCommentIcon />
      </S.AddCommentWrap>
    </Tooltip>
  )
}
